import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { getDKTToken } from '../redux/actions/SSO'
import { getUser } from '../redux/actions/user'

type Tprops = {
  children?: any
  getDKTToken: any
  user: any
  getUser: any
}

const mapStateToProps = (state) => ({
  user: state.user,
})

const mapDispatchToProps = {
  getDKTToken: getDKTToken,
  getUser: getUser,
}

const SSO = ({ children, getDKTToken, user, getUser }: Tprops) => {
  const router = useRouter()
  const isFunnel = ['/information', '/shipping', '/payment'].includes(
    router.pathname
  )
  const currentLocale = router.locale

  useEffect(() => {
    if (
      router &&
      router?.query &&
      router?.query?.code &&
      !router?.query?.product &&
      localStorage?.getItem('TOKEN') !== 'null'
    ) {
      const code = router?.query?.code
      const redirectUrl = isFunnel
        ? currentLocale === 'fr'
          ? `${process.env.SSO_REDIRECT_URL_ON_FUNNEL}`
          : `${process.env.SSO_REDIRECT_URL}de/shipping`
        : currentLocale === 'fr'
        ? `${process.env.SSO_REDIRECT_URL}`
        : `${process.env.SSO_REDIRECT_URL}de`
      const dktLogin = async () => {
        // get the token and save it as auth token
        const token = await getDKTToken(code, redirectUrl)
        if (token.type === 'GET_TOKEN_SUCCESS') {
          localStorage.removeItem('TOKEN')
          localStorage.setItem('connexion-type', process.env.SSO_TYPE)
          localStorage.setItem('TOKEN', token?.token?.access_token)
          localStorage.setItem('user-type', 'SSO')
          await getUser()
          // add redirection to form page to check and add all needed values
        }
      }
      dktLogin()
    }
  }, [])

  useEffect(() => {
    if (
      typeof user?.user !== 'undefined' &&
      user?.user !== '' &&
      router.pathname !== '/account/informations-check'
    ) {
      const fn = user?.user?.firstName
      const ln = user?.user?.lastName
      const pn = user?.user?.phoneNumber
      const gd = user?.user?.gender

      if (
        fn === '' ||
        typeof fn === 'undefined' ||
        fn === null ||
        ln === '' ||
        typeof ln === 'undefined' ||
        ln === null ||
        pn === '' ||
        typeof pn === 'undefined' ||
        pn === null ||
        gd === '' ||
        typeof gd === 'undefined' ||
        gd === null
      ) {
        router.push('/account/informations-check')
      }
    }
  }, [user?.user])

  return children
}

export default connect(mapStateToProps, mapDispatchToProps)(SSO)
